@import "normalize.css";

* {
	box-sizing: border-box;
}

html, body {
	min-width: 320px;
	font-size: 16px;
}

$grid-tiny: 480px;
$grid-small: 698px; //768px;
$grid-medium: 992px;
$grid-large: 1200px;
$grid-extra-large: 1400px;

@mixin breakpoint($arg) {
	@if $arg == tiny {
		@media (max-width: $grid-tiny) {
			@content;
		}
	} @else if $arg == extra-small {
		@media (max-width: $grid-small - 1px) {
			@content;
		}
	} @else if $arg == small {
		@media (min-width: $grid-small) {
			@content;
		}
	} @else if $arg == medium {
		@media (min-width: $grid-medium) {
			@content;
		}
	} @else if $arg == large {
		@media (min-width: $grid-large) {
			@content;
		}
	} @else if $arg == extra-large {
		@media (min-width: $grid-extra-large) {
			@content;
		}
	} @else {
		@media (min-width: $arg) {
			@content;
		}
	}
}

.container {
	margin-left: auto;
	margin-right: auto;
	max-width: 100%;

	@include breakpoint(small) {
		max-width: $grid-small;
	}
	@include breakpoint(medium) {
		max-width: $grid-medium;
	}
	@include breakpoint(large) {
		max-width: $grid-large;
	}
	@include breakpoint(extra-large) {
		max-width: $grid-extra-large;
	}
}

@mixin unlist {
	list-style: none;
	margin: 0;
	padding: 0;
}

img {
	display: block;
	max-width: 100%;
	width: auto;
	margin: auto;
}
