@import "./utils";
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700|Roboto:400,700&display=swap');

$color-primary: hsla(215, 30%, 41%, 1);
$color-secondary: hsla(222, 29%, 58%, 1);
$color-tertiary: hsla(355, 53%, 56%, 1);
$color-tertiary-dark: hsla(355, 42%, 42%, 1);
$white: hsla(0, 100%, 100%, 1);
$font-primary: 'Open Sans', sans-serif;
$font-secondary: 'Roboto', sans-serif;

body {
	color: $color-secondary;
	font-family: $font-secondary;
	line-height: 1.18;
}

h1, h2, h3 {
	color: $color-primary;
	font-family: $font-primary;
}

a {
	color: $color-tertiary;
}

.container {
	padding: 3rem 2rem;

	@include breakpoint(small) {
		padding: 4rem 3rem;
	}

	@include breakpoint(medium) {
		padding: 5rem 4rem;
	}

	@include breakpoint(large) {
		max-width: 1100px;
		padding: 5rem 4rem;
	}
}

.container--post-nav {
	padding-top: 2rem;

	@include breakpoint(small) {
		padding-top: 3rem;
	}

	@include breakpoint(medium) {
		padding-top: 4rem;
	}

	@include breakpoint(large) {
		padding-top: 5rem;
	}
}

.container--nav {
	padding-bottom: 0;
	padding-top: 0;
}

.container--sibling {
	padding-top: 0;
}

/**
 * HEADER
 */
.nav {
	@include unlist();

	align-items: center;
	display: flex;
	font-family: $font-primary;
	font-size: 16px;
	justify-content: space-between;
	padding: 1rem 0;

	a {
		color: $color-secondary;
		text-decoration: none;
	}

	@include breakpoint(small) {
		font-size: 18px;
	}
}

.nav-logo {
	align-items: center;
	display: flex;
}

.nav-logo__copy {
	font-size: 16px;

	@include breakpoint(small) {
		font-size: 18px;
	}

	@include breakpoint(medium) {
		font-size: 18px;
	}
}

.nav-logo__copy__primary {
	font-weight: bold;
}

/**
 * BUTTON
 */
.button {
	background-color: $color-tertiary;
	border: solid 1px $color-tertiary;
	border-radius: 8px;
	color: $white;
	display: inline-block;
	font-size: 14px;
	padding: .4em .8em;
	text-align: center;
	text-decoration: none;

	&:hover {
		background-color: $color-tertiary-dark;
		border-color: $color-tertiary-dark;
		color: $white;
	}

	@include breakpoint(small) {
		border-radius: 12px;
		font-size: 16px;
		padding: .5em 1em;
	}
}

.button--secondary {
	background-color: $white;
	border-color: $color-secondary;
}

.button--tertiary {
	background-color: #080;
	border-color: #080;
}

.button--linkedin {
	background-color: hsla(201, 100%, 35%, 1);
	border-color: hsla(201, 100%, 35%, 1);
}

/**
 * BACKGROUND
 */
.background--secondary {
	background-color: hsla(204, 100%, 96%, 1);
}

.background--tertiary {
	background-color: hsla(253, 15%, 97.5%, 1);
}

.background--footer {
	background-color: hsla(206, 59%, 58%, 1);
}

/**
 * SPLASH
 */
.splash {
	align-items: center;
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-around;
	margin: auto;
	max-width: 450px;

	@include breakpoint(small) {
		flex-direction: row;
		max-width: none;
	}

}

.splash__copy {
	text-align: center;
}

.splash__copy__intro {
	min-height: 3em;
}

.splash__copy__intro__special {
	text-decoration: underline;
}

.splash__copy__action {}

.splash__art__image {
	margin-bottom: 1rem;
	max-width: 240px;

	@include breakpoint(small) {
		margin-bottom: 0;
		margin-left: 2rem;
		max-width: 280px;
	}

	@include breakpoint(medium) {
		max-width: 380px;
	}

	@include breakpoint(large) {
		max-width: 480px;
	}
}

/**
 * MARKET
 */
.market {
}

.market__groups {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: auto;
	max-width: 450px;

	@include breakpoint(small) {
		flex-direction: row;
		max-width: none;
	}
}

.market__group {
	text-align: center;

	&:first-child {
		margin-bottom: 3rem;
	}

	@include breakpoint(small) {
		padding: 0 2rem;

		&:first-child {
			margin-bottom: 0;
		}
	}
}

.market__group__image {
	max-width: 166px;

	@include breakpoint(medium) {
		max-width: 220px;
	}

	@include breakpoint(medium) {
		max-width: 250px;
	}

	@include breakpoint(large) {
		margin-bottom: 2rem;
		max-width: 300px;
	}
}

.market__action {
	margin-top: 1rem;
	text-align: center;

	@include breakpoint(small) {
		margin-top: 2rem;
	}

	@include breakpoint(medium) {
		margin-top: 3rem;
	}
}

/**
 * WHY
 */
.why {
	align-items: center;
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-around;
	margin: auto;
	max-width: 450px;
	text-align: center;

	@include breakpoint(small) {
		flex-direction: row;
		max-width: none;
	}
}

.why__art {
}

.why__art__image {
	max-width: 166px;

	@include breakpoint(small) {
		max-width: 200px;
	}

	@include breakpoint(medium) {
		max-width: 300px;
	}

	@include breakpoint(large) {
		max-width: 325px;
	}
}

.why__info {

	@include breakpoint(small) {
		margin-right: 2rem;
	}

	@include breakpoint(medium) {
		max-width: 400px;
	}
}

.why__info__action {
	margin-top: 1rem;
}

/**
 * ME
 */
.who {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: auto;
	max-width: 450px;

	@include breakpoint(small) {
		flex-direction: row;
		justify-content: center;
		max-width: none;
	}
}

.who__photo {
	margin-bottom: 1rem;

	@include breakpoint(small) {
		margin-bottom: 0;
		margin-right: 2.2rem;
	}

	@include breakpoint(medium) {
		margin-right: 3rem;
	}

	@include breakpoint(large) {
		margin-right: 3.5rem;
	}

}

.who__photo__pic {
	border-radius: 15px;
	max-width: 200px;

	@include breakpoint(small) {
		max-width: 240px;
	}

	@include breakpoint(medium) {
		max-width: 280px;
	}
}

.who__info {
	text-align: center;

	@include breakpoint(small) {
		margin-bottom: 0;
	}
}

.who__info__copy {
	max-width: 300px;

}

.who__info__actions {
	display: flex;
	justify-content: space-around;
	margin-top: 2rem;
}

/**
 * CUSTOMERS
 */
.customers {
	text-align: center;
}

.customers__title {
}

.customers__list {
	@include unlist();
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
}

.customers__list__item {
	//max-width: 25%;
	padding: 1rem;
	text-align: center;

	@include breakpoint(medium) {
		width: 18%;
	}
}

.customers__list__item__logo {
	max-height: 100px;
	max-width: 200px;

	@include breakpoint(medium) {
		max-height: 120px;
		max-width: 220px;
	}
}

.customers__list__item__logo--special {
	max-width: 140px;

	@include breakpoint(medium) {
	}
}

/**
 * TECH
 */
.tech {
	align-items: center;
	display: flex;
	flex-direction: column-reverse;
	justify-content: space-around;
	margin: auto;
	max-width: 450px;

	@include breakpoint(small) {
		flex-direction: row;
		max-width: none;
	}
}

.tech__info {
	margin-bottom: 2rem;

	@include breakpoint(small) {
		margin-bottom: 0;
		margin-left: 3rem;
	}

	@include breakpoint(medium) {
		max-width: 400px;
	}
}

.tech__art {
}

.tech__art__image {
	max-width: 200px;

	@include breakpoint(small) {
		max-width: 250px;
	}

	@include breakpoint(medium) {
		max-width: 280px;
	}

	@include breakpoint(large) {
		max-width: 320px;
	}
}

/**
 * CONTACT
 */
.contact {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin: auto;

	@include breakpoint(small) {
		flex-direction: row;
		max-width: none;
	}
}

.contact-list {
	margin-bottom: 3rem;
}

.contact-list__item {
	align-items: center;
	background-color: $color-tertiary;
	border-radius: 10px;
	color: $white;
	display: flex;
	font-size: 14px;
	justify-content: flex-start;
	margin-bottom: 1rem;
	padding: 1rem .8rem;
	text-decoration: none;

	img {
		margin: 0;
		margin-right: .3rem;
		max-width: 20px;
	}

	@include breakpoint(small) {
		font-size: 16px;
	}

	@include breakpoint(medium) {
		padding: 1.2rem 1.5rem;
	}
}

.contact__art {
}

.contact__art__image {
	max-width: 200px;

	@include breakpoint(small) {
		max-width: 250px;
	}

	@include breakpoint(medium) {
		max-width: 280px;
	}

	@include breakpoint(large) {
		max-width: 300px;
	}
}

/**
 * FOOTER
 */
.footer {
	align-items: center;
	color: $white;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin: auto;
	text-align: center;

	@include breakpoint(small) {
		flex-direction: row-reverse;
		max-width: none;
	}

	a {
		color: $white;
		text-decoration: none;
	}
}

.footer__contact {
	display: none;

	@include breakpoint(small) {
		display: block;
		text-align: right;
	}
}

.footer__logo {
	margin: 3rem;
	max-width: 180px;
	order: -1;

	@include breakpoint(small) {
		order: 0;
	}
}

.footer__copy {
	text-align: left;
}
